// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-post-tsx": () => import("./../src/templates/project-post.tsx" /* webpackChunkName: "component---src-templates-project-post-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-cookie-page-tsx": () => import("./../src/templates/cookie-page.tsx" /* webpackChunkName: "component---src-templates-cookie-page-tsx" */),
  "component---src-templates-careers-page-tsx": () => import("./../src/templates/careers-page.tsx" /* webpackChunkName: "component---src-templates-careers-page-tsx" */),
  "component---src-templates-legal-page-tsx": () => import("./../src/templates/legal-page.tsx" /* webpackChunkName: "component---src-templates-legal-page-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-templates-not-found-page-tsx": () => import("./../src/templates/not-found-page.tsx" /* webpackChunkName: "component---src-templates-not-found-page-tsx" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-hobbes-lottie-test-jsx": () => import("./../src/pages/hobbes-lottie-test.jsx" /* webpackChunkName: "component---src-pages-hobbes-lottie-test-jsx" */),
  "component---src-pages-project-index-js": () => import("./../src/pages/project/index.js" /* webpackChunkName: "component---src-pages-project-index-js" */),
  "component---src-pages-tags-index-jsx": () => import("./../src/pages/tags/index.jsx" /* webpackChunkName: "component---src-pages-tags-index-jsx" */)
}

